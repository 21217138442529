@import "variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// font
$font-family-sans-serif: "Nunito Sans", sans-serif;
$font-family-monospace: "Ubuntu Mono", monospace;

// colors
$theme-colors: map-merge(
  $theme-colors,
  (
    "primary": $primary,
    "warning": $warn,
  )
);
$focus-ring-opacity: 1;

// body
$body-bg: $bg;
$body-color: $base;

// offcanvas
$offcanvas-horizontal-width: 35%;

// nav
$navbar-nav-link-padding-x: 2rem;
$navbar-light-active-color: #fff;
$nav-pills-link-active-bg: $primary;
$nav-pills-border-radius: $default-rounding;

// button
$btn-border-radius: $default-rounding;
$btn-disabled-opacity: 0.3;
$btn-padding-x: 2rem;

// close button
$btn-close-color: $base;
$btn-close-opacity: 1;

// card
$card-border-radius: $default-rounding;
$card-border-width: 0;
$card-color: $base;

// form
$form-check-input-bg: #696969;

// toast
$toast-background-color: #fff;
$toast-header-border-color: transparent;
$toast-header-color: $base;
$toast-box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
  rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
  rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
$toast-border-radius: $default-rounding;

// progress bar
$progress-border-radius: none;
$progress-height: 4px;
$progress-border-radius: $default-rounding;
$progress-bar-bg: $base;
$progress-bg: #c8c6c6;

// accordion
$accordion-button-color: $base;
$accordion-button-active-bg: white;
$accordion-button-active-color: $primary;
$accordion-body-padding-y: 0.75rem;
$accordion-body-padding-x: 0.75rem;

// input
$input-focus-border-color: $primary;
$input-btn-focus-box-shadow: 0 0 3px 3px $primary;

// table
$table-hover-bg: $hover-color;

// select
$form-select-color: $base;
$form-select-indicator-color: $base;

@import "~bootstrap/scss/bootstrap";

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e") !important;
}
