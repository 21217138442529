/*
*  Created by Rouven Haake 2022, Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

.trace-information-container {
    overflow: hidden;
    overflow-y: scroll;
    height: 100%;
    background-color: white;
}

//trick to make thead border sticky
.topic-trace-table tbody {
    border-top: none !important;
}

.topic-trace-table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none !important;
}

.topic-trace-table thead th {
    border-top: none !important;
    border-bottom: none !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    box-shadow: inset 0 -2px 0 #000000;
    background-color: white;
}

.topic-trace-table tr td:nth-child(2),
.topic-trace-table tr th:nth-child(2) {
    text-align: right;
}

.topic-trace-table tbody tr:hover {
    cursor: pointer;
}

.topic-trace-table tbody {
    display: grid;
    grid-template-columns: auto auto;
}

.topic-trace-table thead {
    display: grid;
    grid-template-columns: auto auto;
}

.topic-trace-table tr {
    display: contents;
    /* not supported in safari or ios
    https://caniuse.com/css-display-contents
    */
}

.topic-trace-table td {
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
}