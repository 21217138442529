/*
*  Created by Rouven Haake 2022, Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

.trace-markdown-container {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}

.trace-markdown-header {
  font-size: 1.4rem;
  padding: 0.2rem 0 0.2rem 0.2rem;
}
