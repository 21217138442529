/*
*  Created by Rouven Haake 2022, Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

@use "theme";
@use "container";
@use "split";
@use "search";
@use "topic_list";
@use "trace_overview";
@use "topic_details";
@use "login";

@import "variables";
@import url("https://fonts.cdnfonts.com/css/nunito-sans");
@import url("https://fonts.cdnfonts.com/css/ubuntu-mono");

* {
  color: $base;
}

body {
  height: 100vh;
  min-height: 100vh;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.shadow-iav {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

  border-radius: $default-rounding;
}

.main-body {
  height: calc(100vh - 56px);
}
