/*
*  Created by Rouven Haake 2022, Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

.dashboard-panel {
  padding: 0 0.25rem 0.25rem 0.25rem;

  background-color: #fff;
  border-radius: 4px;
}

.tile-container {
  min-height: 44vh;
}
