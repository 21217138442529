/*
*  Created by Rouven Haake 2022, Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

$glass: rgba(255, 255, 255, 0.2);
$glass-icon: rgba(255, 255, 255, 0.3);

$layout-breakpoint-mid: 1300px;

@import "variables";

.filter-bar {
  height: 75px;
  justify-content: center;
  align-items: center;
  margin-left: 20rem;

  @media (min-width: $layout-breakpoint-mid) {
    margin-left: 30rem;
  }
}

.filter-list {
  height: 35vh;
}

.filter-menu {
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  /* Default */
  justify-content: space-between;
}

.filter-menu > li {
  display: inline-block;
}

.selection-span {
  font-weight: bold;
  padding-left: 1rem;
}

.selection-topic-item {
  padding-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selection-trace-item {
  padding-left: 1rem;
}

.selection-padding {
  width: 5vw;
}

.search-field {
  position: relative;
}

.search-field .fa-search {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #9ca3af;
}

.form-input {
  height: 55px;
  text-indent: 33px;
}

.search-field span {
  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
}

.collapsible .filter-menu {
  background-color: #eeeeee;
}

.collapsible .filter-header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

.collapse-btn {
  display: block;
  width: 100%;
  padding: 0.2rem 0 0.2rem 1rem;
  border-radius: 0 0 3px 3px;
  background-color: white;
  font-size: 1.4rem;
}

.collapse-content.collapsed {
  display: none;
}

.collapse-content.expanded {
  display: block;
}

.topic-list {
  overflow: hidden;
  overflow-y: scroll;
  max-height: var(--filter-height);
  border-radius: $default-rounding;
}

.topic-line:nth-child(even) {
  background-color: $bg;
}

.topic-btn {
  padding-left: 1rem;
  border: none;
  background-color: inherit;
  display: block;
  width: 100%;
}

.topic-btn:hover {
  background-color: $hover-color;
}

.topic-count {
  overflow: hidden;
  overflow-y: scroll;
  max-height: var(--filter-height);
  min-width: 18rem;
}

.topic-count-header {
  position: sticky;
  top: 0;
}

//trick to make thead border sticky
.topic-count-table tbody {
  border-top: none !important;
}

.topic-count-table tbody:nth-of-type(1) tr:nth-of-type(1) td {
  border-top: none !important;
}

.topic-count-table thead th {
  border-top: none !important;
  border-bottom: none !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: inset 0 -2px 0 #000000;
  background-color: white;
}

.topic-count-table tr td:nth-child(2),
.topic-count-table tr th:nth-child(2) {
  text-align: right;
}

.topic-count-table tbody tr:hover {
  cursor: pointer;
}

.search-input {
  width: 32rem;
}
