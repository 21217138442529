/*
*  Created by Rouven Haake 2022, Christopher Lang 2022
*  Copyright © 2022 IAV GmbH. All rights reserved.
*/

.topic-detail-container {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}

.topic-detail-header {
  font-weight: bold;
  padding: 0.25rem;
}

.topic-detail-data {
  font-size: 1rem;
  padding: 0 0.5rem;
}

.topic-detail-info {
  padding-left: 4px;
  font-size: small;
  font-style: italic;
  color: gray;
}

.topic-detail-table {
  border-width: 1px;
  border-spacing: 20px 20px;
  border-collapse: separate;
  td {
    vertical-align: top;
  }
}

.topic-detail-schemas {
  border-width: 1px;
  // border-spacing: 6px 20px;
  border-collapse: collapse;

  td {
    vertical-align: top;
  }

  td:nth-child(2n) {
    padding-left: 20px;
  }
  th:nth-child(2n) {
    padding-left: 20px;
  }

  th {
    font-weight: normal;
    padding-top: 1em;
  }
}
